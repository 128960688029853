import "./input.scss"

import React, { useState } from "react"
import classnames from "classnames"

const mainClass = "input"

const Input = ({
  id,
  type,
  name,
  value,
  disabled,
  className,
  onChange,
  placeholder,
  required,
  label,
  errors,
  field,
}) => {
  const [inputType, setInputType] = useState(type)

  return (
    <div className={classnames(mainClass, className)}>
      <input
        className="input__input"
        type={inputType}
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        placeholder={`${placeholder}${required ? "*" : ""}`}
        required={required}
        {...field}
        onChange={(e) => {
          field?.onChange(e)
          onChange?.(e)
        }}
      />
      {type === "password" && (
        <button
          type="button"
          onClick={e => {
            e.preventDefault()
            inputType === "password"
              ? setInputType("text")
              : setInputType("password")
          }}
          className="input__password"
        >
          Show password
        </button>
      )}
      {label && <span className="input__label">{label}</span>}
      {errors && errors.hasOwnProperty(name) && (
        <span className="input__error">{errors[name]}</span>
      )}
    </div>
  )
}

export default Input
