import "styles/pages/login.scss"

import React, { useState, useEffect } from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { isEmpty } from "lodash"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import LoginForm from "components/LoginForm/LoginForm"
import Button from "components/Button/Button"
import { isUserLoggedIn } from "utils/functions"

const Login = () => {
  const title = "Zaloguj się"
  const [checkoutParam] = useQueryParam("checkout", StringParam)

  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const auth = isUserLoggedIn()

    if (!isEmpty(auth)) {
      setLoggedIn(true)
    }
  }, [loggedIn])

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="login">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-7">
              <PageSubHeader title="Dostęp do konta" />
              <LoginForm setLoggedIn={setLoggedIn} />
            </div>
            <div className="col-xl-4 offset-xl-1 col-md-6">
              <PageSubHeader
                title="Nie posiadasz konta?"
                className="page__sub-header--less-m"
              />
              <p className="login__desc">
                Założenie konta w naszym serwisie pozwala na dostęp do panelu
                użytkownika zawierającego szczegóły twojego konta tj. historia
                zamówień czy dane teleadresowe.
              </p>
              <Button
                type="link"
                to="/rejestracja/"
                className="button--bordered"
              >
                Zarejestruj się
              </Button>
            </div>
            <div className="col-xl-3 col-md-6">
              {checkoutParam === "1" ? (
                <>
                  <PageSubHeader title="Nie zakładasz konta?" />
                  <p className="login__desc">
                    Nie masz konta w naszym sklepie, a chciałbyś dokonać zakupu?
                    Skorzystaj z opcji zakupu bez rejestracji i ciesz się nowym
                    sprzętem już teraz!
                  </p>
                  <Button
                    type="link"
                    to="/zamowienie/"
                    className="button--bordered"
                  >
                    Zamów jako gość
                  </Button>
                </>
              ) : (
                <>
                  <PageSubHeader title="Dlaczego warto?" />
                  <p className="login__desc">
                    Jesteśmy na rynku IT nieprzerwanie od 2001 roku. Przez ten
                    czas zdobyliśmy mnóstwo cennego doświadczenia, dzięki
                    któremu możemy świadczyć obsługę na najwyższym poziomie.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Login
