import "./login-form.scss"

import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { isEmpty } from "lodash"
import { useMutation } from "@apollo/client"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import Input from "components/Input/Input"
import Button from "components/Button/Button"

import LOGIN from "mutations/login"
import { isUserValidated, setAuth } from "utils/functions"
import validateAndSanitizeLoginForm from "validator/login"

const LoginForm = ({ setLoggedIn }) => {
  const isBrowser = typeof window !== "undefined"

  // Check if the user is validated already and redirect
  if (isBrowser) {
    const userValidated = isUserValidated()

    if (!isEmpty(userValidated)) {
      navigate("/moje-konto/")
    }
  }

  const [loginFields, setLoginFields] = useState({
    username: "",
    password: "",
  })

  const [login, { loading: loginLoading, error: loginError }] = useMutation(
    LOGIN,
    {
      variables: {
        input: {
          clientMutationId: v4(),
          username: loginFields.username,
          password: loginFields.password,
        },
      },
      onCompleted: data => {
        if (!isEmpty(loginError)) {
          toast.error("Wystąpił błąd")
          console.warn(loginError.graphQLErrors[0].message)
        }

        const { login } = data
        const authData = {
          authToken: login.authToken,
          user: login.user,
        }

        setAuth(authData)
        setLoggedIn(true)
        toast.success("Poprawnie zalogowano!")
        navigate("/moje-konto/")
      },
      onError: error => {
        if (error) {
          if (!isEmpty(error)) {
            error.message === "alg_wc_ev_email_verified_error"
              ? toast.warning("Konto nie zostało jeszcze aktywowane.")
              : error.message === "invalid_username"
              ? toast.warning("Niepoprawna nazwa uytkownika lub email.")
              : toast.error("Wystąpił błąd test")

            console.warn(error.graphQLErrors[0].message)
          }
        }
      },
    }
  )

  const onFormSubmit = event => {
    event.preventDefault()

    const validationResult = validateAndSanitizeLoginForm({
      username: loginFields.username,
      password: loginFields.password,
    })

    if (validationResult.isValid) {
      setLoginFields({
        username: validationResult.sanitizedData.username,
        password: validationResult.sanitizedData.password,
      })
      login()
    } else {
      setClientSideError(validationResult)
    }
  }

  //
  const setClientSideError = validationResult => {
    if (validationResult.errors.password) {
      toast.error("Wystąpił błąd")
      console.warn(validationResult.errors.password)
    }

    if (validationResult.errors.username) {
      toast.error("Wystąpił błąd")
      console.warn(validationResult.errors.username)
    }
  }

  const handleOnChange = event => {
    setLoginFields({ ...loginFields, [event.target.name]: event.target.value })
  }

  const { username, password } = loginFields

  return (
    <form onSubmit={onFormSubmit} className="login-form">
      <Input
        type="text"
        name="username"
        placeholder="E-mail"
        value={username}
        onChange={handleOnChange}
      />
      <Input
        type="password"
        name="password"
        placeholder="Hasło"
        value={password}
        onChange={handleOnChange}
      />
      <div className="login-form__cta">
        <p>
          Nie pamiętasz hasła? <Link to="/resetowanie-hasla/">Zresetuj je</Link>
        </p>
        <p>
          Nie masz konta? <Link to="/rejestracja/">Zarejestruj się</Link>
        </p>
      </div>
      <div className="login-form__button">
        <Button type="submit">
          {loginLoading ? "Logowanie..." : "Zaloguj się"}
        </Button>
      </div>
    </form>
  )
}

export default LoginForm
